import { ContactModal } from 'components/ContactModal/ContactModal'
import { LegalModal } from 'components/LegalModal/LegalModal'
import { useCheckHistory } from 'hooks/useCheckHistory'
import React, { useState } from 'react'
import styles from './Footer.module.scss'

interface FooterProps { }

export const Footer: React.FC<FooterProps> = (props) => {
  const [isContactVisible, setIsContactVisible] = useState<boolean>(false)
  const [isLegalVisible, setIsLegalVisible] = useState<boolean>(false)

  const historyResult = (foundParam: string) => {
    if (foundParam === "legal") {
      setIsLegalVisible(true)
    }
    else if (foundParam === "contact") {
      setIsContactVisible(true)
    }
  }

  useCheckHistory(["legal", "contact"], historyResult)

  const onContactClose = () => {
    setIsContactVisible(false)
  }
  const onLegalClose = () => {
    setIsLegalVisible(false)
  }

  const ContactModalObj = (
    isContactVisible ? <ContactModal closeFunction={onContactClose} /> : null
  )

  const LegalModalObj = (
    isLegalVisible ? <LegalModal closeFunction={onLegalClose} /> : null
  )

  return (
    <div className={styles.Footer}>
      <div className={styles.leftItems} >
        <span className={styles.title}>
          {`${new Date().getFullYear()}`}
        </span>
      </div>
      <div className={styles.rightItems} >
        <button className={styles.links} onClick={() => { setIsContactVisible(true) }}>
          {"Contact"}
        </button>
        <button className={styles.links} onClick={() => { setIsLegalVisible(true) }}>
          {"Legal"}
        </button>
      </div>
      {ContactModalObj}
      {LegalModalObj}
    </div>
  )
}