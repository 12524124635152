import { AppCarousel } from 'components/AppCarousel/AppCarousel';
import { SoftwareModal } from 'components/SoftwareModal/SoftwareModal';
import { Games } from "data/Games";
import { GamesInDevelopment } from "data/GamesInDevelopment";
import React, { useState } from 'react';
import { AppModel } from 'types/AppModel';
import styles from './GamesPage.module.scss';

interface GamesPageProps { }

export const GamesPage: React.FC<GamesPageProps> = (props) => {
  const [isSoftwareModalVisible, setIsSoftwareModalVisible] = useState(false)
  const [selectedAppModel, setSelectedAppModel] = useState<AppModel | null>(null)

  const modal = isSoftwareModalVisible ? <SoftwareModal appModel={selectedAppModel} closeFunction={() => setIsSoftwareModalVisible(false)} /> : null
  return (
    <div className={styles.GamesPage}>
      <div className={styles.description}>
        <div className={styles.card}>
          <span className={styles.title}>{"Description"}</span>
          {"Games are some of my favorite things to create. I once had a computer science professor say that there is no higher form of expression in computer science than creating games. They require skills in everything from art to networking, and I'm excited and grateful to be able to share my games with you."}
        </div>
      </div>
      <AppCarousel title="Games" apps={Games} onAppClick={(e) => { setIsSoftwareModalVisible(true); setSelectedAppModel(e) }} tooltip={"Games currently available on the market"} />
      <AppCarousel title="Games in Development" apps={GamesInDevelopment} onAppClick={(e) => { setIsSoftwareModalVisible(true); setSelectedAppModel(e) }} tooltip={"Games in design and development"} />
      {modal}
    </div>
  )
}