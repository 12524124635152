import React from 'react';
import { useHistory } from 'react-router';
import styles from './Header.module.scss';

interface HeaderProps { }

export const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  return (
    <div className={styles.Header}>
      <div className={styles.leftItems} >
        <span className={styles.title} onClick={() => history.push("/")}>
          {"Software Unwritten"}
        </span>
      </div>
      <div className={styles.rightItems} >
        <button className={styles.links} onClick={() => history.push("/applications")}>
          {"Applications"}
        </button>
        <button className={styles.links} onClick={() => history.push("/games")}>
          {"Games"}
        </button>
        <button className={styles.links} onClick={() => history.push("/")}>
          {"About"}
        </button>
      </div>
    </div>
  )
}