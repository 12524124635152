import { ModalBase } from 'components/ModalBase/ModalBase'
import { useSyncURL } from 'hooks/useSyncURL'
import React from 'react'
import styles from './ContactModal.module.scss'

interface ContactModalProps {
  closeFunction: Function;
}

export const ContactModal: React.FC<ContactModalProps> = (props) => {
  const { closeFunction } = props
  useSyncURL("contact")

  return (
    <ModalBase closeFunction={closeFunction}>
      <div className={styles.ContactModal}>
        <p >
          {"For all questions, inquiries, concerns, feedback, and other forms of electronic contactery, use the email below"}
        </p>
        <p >
          {"ibber@myokcorral.com"}
        </p>
      </div>
    </ModalBase>
  )
}