import React from 'react'
import styles from './Backdrop.module.scss'

interface BackdropProps {
  onBackdropClick: Function;
}

export const Backdrop: React.FC<BackdropProps> = (props) => {
  const { onBackdropClick } = props
  return (
    <div className={styles.Backdrop} onClick={() => onBackdropClick(false)}>
    </div>
  )
}