import { Backdrop } from 'components/Backdrop/Backdrop'
import React from 'react'
import ReactDOM from 'react-dom'

interface ModalBaseProps {
  closeFunction: Function;
 }

export const ModalBase: React.FC<ModalBaseProps> = (props) => {
  const {closeFunction} = props
  const portalEl = document.getElementById('portal')

  const content = (
    <>
      <Backdrop onBackdropClick={closeFunction} />
      {props.children}
    </>
  )

  return ReactDOM.createPortal(content, portalEl!)
}