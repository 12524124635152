import React from 'react'
import { AppModel } from 'types/AppModel'
import styles from './AppIcon.module.scss'

interface AppIconProps {
  appModel: AppModel
}

export const AppIcon: React.FC<AppIconProps> = (props) => {
  const { appModel } = props

  return (
    <>
      <img key={appModel.name} className={styles.images} src={`${process.env.PUBLIC_URL}/${appModel.image}`} alt={appModel.name + " Placeholder"} />
    </>
  )
}