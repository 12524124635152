import { useEffect } from "react"
import { useHistory } from "react-router"

export const useCheckHistory = (paramsToCheck: string[], callback: Function) => {
  const history = useHistory()
  useEffect(() => {
    const pathArray = history.location.pathname.split("/")
    let path: string
    if (pathArray.length > 0) {
      path = pathArray[pathArray.length - 1]
    }
    else {
      path = history.location.pathname
    }

    paramsToCheck.forEach((x) => {
      var PATTERN = /[^A-Za-z]/g;
      var cleanX = x.toLowerCase().replace(PATTERN, '')

      if (cleanX === path) {
        callback(x)
        return
      }
    })

  }, [callback, history.location.pathname, paramsToCheck])


}