import { AppModel } from "../types/AppModel";

export const Applications: AppModel[] = [{
  name: "Noteify",
  image: process.env.PUBLIC_URL + 'Assets/Noteify.png',
  description: "Noteify is the first flashcard app that utilizes the powerful notification system in iOS to quiz you on your flashcards. Using a customizable spaced repetition algorithm Noteify quizzes you on your flashcards throughout the day without having to ever open the app. Gone are the days of creating a flashcard set and forgetting to study it. With Noteify your flashcards are always with you, and they arrive at intervals to make sure that you never forget them. No more cramming for tests because you will never forget the information in the first place. What are you waiting for? Download the app and get notified!",
  upVotes: 0,
  downVotes: 0,
  docsLink: ""
},
]