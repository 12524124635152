import { AppIcon } from 'components/AppIcon/AppIcon'
import { ModalBase } from 'components/ModalBase/ModalBase'
import { useSyncURL } from 'hooks/useSyncURL'
import React from 'react'
import { AppModel } from 'types/AppModel'
import styles from './SoftwareModal.module.scss'

interface SoftwareModalProps {
  closeFunction: Function;
  appModel: AppModel | null;
}

export const SoftwareModal: React.FC<SoftwareModalProps> = (props) => {
  const { closeFunction, appModel } = props;
  useSyncURL(appModel?.name ?? "");

  return (
    appModel !== null ?
      <ModalBase closeFunction={closeFunction}>
        <div className={styles.SoftwareModal} >
          <div className={styles.leftColumn}>
            <span className={styles.image}>
              <AppIcon appModel={appModel} />
            </span>
            {/* <div className={styles.voteButtons}>
              <button className={styles.icon} onClick={() => { }} >
                {"⬆️"}
              </button>
              <button className={styles.icon} onClick={() => { }} >
                {"⬇️️"}
              </button>
            </div>
            <a href={"/"}>{"Documentation"}</a> */}
          </div>
          <div className={styles.rightColumn}>
            <span className={styles.title}>
              {appModel?.name}
            </span>
            <p className={styles.description}>
              {appModel?.description}
            </p>
          </div>
        </div >
      </ModalBase > : null
  )
}