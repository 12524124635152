import React from 'react'
import styles from './AboutPage.module.scss'

interface AboutPageProps { }

export const AboutPage: React.FC<AboutPageProps> = (props) => {
  return (
    <div className={styles.AboutPage}>
      <div className={styles.wrapper}>

      <div className={styles.card}>
        <span className={styles.title}>
          {"About"}
        </span>

        <span className={styles.content}>
          {"Software Unwritten is the place where all of my passion projects accumulate. Basically if I find a piece of software that doesn't exist, but should, or if want to learn a new skill and need to build a project to do it, then those projects end up here. Currently I'm manning the ship solo, and as I have another full time job that makes actual money, I don't have all that much time to work on the projects here, but rest assured, I do work on them. If you do find some of these ideas useful, let me know, and I'll be sure to put some time in working to improve them."}
        </span>
      </div>
      </div>
    </div>
  )
}