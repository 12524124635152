import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import { AboutPage } from 'containers/AboutPage/AboutPage';
import { GamesPage } from 'containers/GamesPage/GamesPage';
import { SoftwarePage } from 'containers/SoftwarePage/SoftwarePage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styles from './App.module.scss';

export const App = () => {
  return (
    <Router>
      <div className={styles.App}>
        <header className={styles.header}>
          <Header />
        </header>
        <div className={styles.main}>
          <Switch>
            <Route path="/applications/:id?" >
              <SoftwarePage />
            </Route>
            <Route path="/games/:id?" >
              <GamesPage />
            </Route>
            <Route path="/">
              <AboutPage />
            </Route>
          </Switch>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </Router>
  );
}
