import { AppCarousel } from 'components/AppCarousel/AppCarousel';
import { SoftwareModal } from 'components/SoftwareModal/SoftwareModal';
import { Applications } from "data/Applications";
import { ApplicationsInDevelopment } from "data/ApplicationsInDevelopment";
import { useCheckHistory } from 'hooks/useCheckHistory';
import React, { useState } from 'react';
import { AppModel } from 'types/AppModel';
import styles from './SoftwarePage.module.scss';
interface SoftwarePageProps {
}

export const SoftwarePage: React.FC<SoftwarePageProps> = (props) => {
  const [isSoftwareModalVisible, setIsSoftwareModalVisible] = useState(false)
  const [selectedAppModel, setSelectedAppModel] = useState<AppModel | null>(null)
  const historyResult = (foundParam: string) => {
    if (isSoftwareModalVisible) { return }
    const foundApp = Applications.find(app => app.name === foundParam) ?? null
    if (foundApp) {
      setIsSoftwareModalVisible(true)
      setSelectedAppModel(foundApp)
    }
  }

  useCheckHistory(Applications.map(x => x.name), historyResult)

  const modal = isSoftwareModalVisible ? <SoftwareModal appModel={selectedAppModel} closeFunction={() => setIsSoftwareModalVisible(false)} /> : null
  return (
    <div className={styles.SoftwarePage}>
      <div className={styles.description}>
        <div className={styles.card}>
          <span className={styles.title}>{"Description"}</span>
          {"These are programs that are designed to be helpful in real life, providing either unique utilities that similar applications lack, or providing functionality that currently doesn't exist elsewhere. My favorite thing to do with software is to push the boundaries of what is possible, so if something ends up here, you can be sure that it's going to do something really cool, and fairly unique ;)"}
        </div>
      </div>
      <AppCarousel title="Applications" apps={Applications} onAppClick={(e) => { setIsSoftwareModalVisible(true); setSelectedAppModel(e) }} tooltip={"Applications currently available on the market"} />
      <AppCarousel title="Applications in Development" apps={ApplicationsInDevelopment} onAppClick={(e) => { setIsSoftwareModalVisible(true); setSelectedAppModel(e) }} tooltip={"Applications in design and development"} />
      {modal}
    </div>
  )
}