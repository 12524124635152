import { AppIcon } from 'components/AppIcon/AppIcon'
import React from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { AppModel } from 'types/AppModel'
import styles from './AppCarousel.module.scss'

interface AppCarouselProps {
  title: string
  apps: AppModel[]
  onAppClick: (e: AppModel) => void
  tooltip: string
}

export const AppCarousel: React.FC<AppCarouselProps> = (props) => {
  const { title, apps, onAppClick, tooltip } = props

  const images = apps.map((x) => {
    return (
      <button key={x.name} className={styles.imageButton} onClick={(e) => onAppClick(x)} >
        <AppIcon appModel={x} />
      </button>
    )
  })

  return (
    <div className={styles.AppCarousel}>
      <div className={styles.titleWrapper} >
        <span className={styles.title}>
          {title}
        </span>
        <span className={styles.info} >
          <MdInfoOutline size={20} />
          <span className={styles.tooltiptext} >
            {tooltip}
          </span>
        </span>
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.appContainer}>
        {images}
      </div>
    </div>
  )
}