import { ModalBase } from 'components/ModalBase/ModalBase'
import { useSyncURL } from 'hooks/useSyncURL'
import React from 'react'
import styles from './LegalModal.module.scss'

interface LegalModalProps {
  closeFunction: Function;
}

export const LegalModal: React.FC<LegalModalProps> = (props) => {
  const { closeFunction } = props
  useSyncURL("legal")

  return (
    <ModalBase closeFunction={closeFunction}>
      <div className={styles.LegalModal}>
        <div >
          <div >
            <h2>
              Software Unwritten Privacy Policy
            </h2>
          </div>
          <div>
            <p>
              Software Unwritten, L.C. is a commercial software development company. This website, and all the applications featured on it, are provided by Software Unwritten, L.C.and are intended for use as is. To assist us in analyzing how our Service is used. We want to inform our users that these third parties have access to information gathered from your device. For gaming related software, Unity Ads and Analytics. At this time, no analytics are being tracked for our other software. The reason these packages need device information is to allow us to improve our products, and in the case of ascension which is a free application, to provide some non targeted ad revenue. However, all third parties are obligated not to disclose or use device information any other purposes.
            </p>
          </div>
          <div >
            <h2 >Security</h2>
          </div>
          <div >
            <p>
              We value your trust in providing us with your device information, thus we are striving to use all commercially acceptable means of protecting it. Remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.  </p>
          </div>
          <div >
            <h2>Links to Other Sites </h2>
          </div>
          <div >
            <p>
              Some of our services may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us, therefore, we strongly advise you to review the privacy policies of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>
          </div>
          <div >
            <h2>Changes to This Privacy Policy</h2>
          </div>
          <div >
            <p>
              We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new privacy policy on this page. Changes are effective immediately after they are posted on this page.
            </p>
          </div>
          <div >
            <h2 >Contact Us</h2>
          </div>
          <div>
            <p>
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at ibber@myokcorral.com
            </p>
          </div>
        </div>
      </div>
    </ModalBase >
  )
}